import React from 'react'

import Component from '../components'
import Layout from '../layouts/MainLayout'
import ReadMoreText from '../components/ReadMoreText'

// ENV variables
const envFile = require('../../env')
const env = envFile.env[process.env.BENV || 'prod']

const PageTemplate = ({
  pageContext,
  pageContext: {
    page,
    knechtDestination,
    mainDestination,
    destination,
    destinations,
    isRootPage,
    url,
    langSlug,
    lang,
    absoluteUrl,
    host,
    hsForms,
    newsletterForm
  }
}) => {
  const contentElements = page.contentElements?.value?.parsed
  const h1 = page.h1?.value
  const h2 = page.h2?.value
  let introText = page.introText?.value

  const {
    slug: { value: destinationSlug }
  } = destination
  const isKnechtHomePage =
    destinationSlug === env.homepage_destination && isRootPage

  const additionalScripts = env?.additionalScripts || []

  // Search for links and add langSlug with last /
  if (langSlug && introText) {
    introText = introText.replace(/ href="\//g, ' href="/' + langSlug)
  }

  // single color for all pages
  if (env.single_color) {
    switch (typeof env.single_color) {
      case 'boolean':
        destination.color.value = knechtDestination.color.value
        break
      case 'string':
        destination.color.value = env.single_color
        break
      default:
    }
  }

  const processAdditionalScripts = () => {
    return (
      additionalScripts &&
      additionalScripts.map((scriptTag, index) => {
        if (
          (scriptTag?.onlyKnecht === true && isKnechtHomePage) ||
          (scriptTag?.onlyKnecht === false && isRootPage)
        ) {
          return scriptTag?.src ? (
            <script
              key={index}
              type={scriptTag?.type}
              src={scriptTag?.src}
              defer
            />
          ) : (
            <script key={index} type={scriptTag?.type} defer>
              {scriptTag?.content}
            </script>
          )
        }

        return null
      })
    )
  }

  return (
    <>
      <Layout
        pageContext={{
          page,
          knechtDestination,
          mainDestination,
          destination,
          isRootPage,
          url,
          langSlug,
          lang,
          absoluteUrl,
          destinations,
          host,
          hsForms,
          newsletterForm,
          additionalScripts: processAdditionalScripts()
        }}
      >
        {(h1 || h2 || introText) && (
          <div className='content-element story-group page-intro'>
            {/* {h1 && <h1 className='visible-desktop'>{h1}</h1>} */}
            {h1 && <h1 className=''>{h1}</h1>}
            {h2 && <h2>{h2}</h2>}
            {introText && <ReadMoreText>{introText}</ReadMoreText>}
          </div>
        )}

        {contentElements?.map((element, index) =>
          Component(element, pageContext, index)
        )}
      </Layout>

      {/* TODO: remove */}
      {/* {page?.pageId?.value && (
        <div
          className='dev'
          style={{
            position: 'fixed',
            zIndex: 200,
            top: 0,
            left: 0
          }}
        >
          <div className='content-element-component'> {page.pageId.value}</div>
        </div>
      )} */}
    </>
  )
}

export default PageTemplate
