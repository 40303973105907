import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { getFluidImage } from '../utils/images'
import { chunkArray, getComponentClass, getComponentId, isInternalUrl } from '../utils/helpers'

const BoxReference = ({ settings, pageContext }) => {
  const { boxElements } = settings
  const { langSlug } = pageContext
  const destinationColor = pageContext?.destination?.color?.value || 'gray'
  
  const handleWindowSizeChange = () => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
      window.addEventListener('resize', handleWindowSizeChange)
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange)
      }
    }
  }, [])
  const [width, setWidth] = useState()
  const isMobile = width <= 768

  const MAX_BOXES_PER_ROW = isMobile ? 2 : 3
  let firstRow = null
  let mainRows = null

  const boxElementsCount = (boxElements && boxElements.length > 0) ? parseInt(boxElements.length, 10) : 0
  
  if (boxElementsCount > 0) {
    if (boxElementsCount % MAX_BOXES_PER_ROW === 0) {
      mainRows = chunkArray(boxElements, MAX_BOXES_PER_ROW)
    } else {
      if (boxElementsCount < MAX_BOXES_PER_ROW) {
        firstRow = boxElements
      } else {
        const count = boxElementsCount % MAX_BOXES_PER_ROW
        firstRow = boxElements.slice(0, count)
        const sanitizedBoxElements = boxElements.slice(count)
        mainRows = chunkArray(sanitizedBoxElements, MAX_BOXES_PER_ROW)
      }
    }
  }

  const componentId = getComponentId(settings)

  const renderBoxImage = image => {
    return (
      <Img
        fluid={getFluidImage({
          cloudinary: image?.public_id,
          ar: 1.55,
          width: '20px',
          // maxWidth: 363,
          maxWidth: 1100,
          // widths: [320, 363]
          widths: [348, 536, 1100]
        })}
        alt={image?.meta?.description || 'box reference'}
        title={image?.meta?.description}
        className='hover-zoom'
        loading='lazy'
        placeholderStyle={{ backgroundColor: 'lightgray' }}
      />
    )
  }

  const renderBoxContent = text => {
    return (
      <div
        className='box-item-header'
        style={{ background: destinationColor }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  }

  const renderBoxItem = (element, index) => {
    const url = element.value?.url?.value
    let text = element.value?.text?.value
    // replace <h4> tags with <h3>
    text = text.replace('<h4>', '<h3>')
    text = text.replace('</h4>', '</h3>')
    const image = element.value?.image?.value?.[0]
    return (
      <div className='box-item' key={index}>
          {isInternalUrl(url) ? (
            <Link to={`/${langSlug}${url}`}>
              {renderBoxContent(text)}
              {renderBoxImage(image)}
            </Link>
          ) : (
            <a href={url} target='_blank' rel='noreferrer'>
              {renderBoxContent(text)}
              {renderBoxImage(image)}
            </a>
          )}
      </div>
    )
  }

  return (
    <div
      id={componentId}
      className={`box-reference ${getComponentClass(settings)}`}
    >
      {firstRow && (
        <div className={`row ${(firstRow.length === 1 || isMobile) ? 'row-1-column' : 'row-2-columns'}`}>
          {firstRow.map((element, index) => {
            return renderBoxItem(element, index)
          })}
        </div>
      )}
      {mainRows && mainRows.map((row, rowIndex) => (
        <div className={`row ${isMobile ? 'row-2-columns' : 'row-3-columns'}`} key={rowIndex}>
          {row.map((element, index) => {
            return renderBoxItem(element, index)
          })}
          </div>
      ))}
    </div>
  )
}

export default BoxReference
